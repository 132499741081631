import {initializeApp} from 'firebase/app'
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBZmylZ6McEa5V1AzfWcMrgBkcD47Vf0pA",
  authDomain: "subsription-form-c5adc.firebaseapp.com",
  projectId: "subsription-form-c5adc",
  storageBucket: "subsription-form-c5adc.appspot.com",
  messagingSenderId: "836443060276",
  appId: "1:836443060276:web:e7f15a27bbf5c3a78f3261",
  measurementId: "G-3HCMH6DH41",
};

const firebaseApp =initializeApp(firebaseConfig);

const db = getFirestore();

export default db;
