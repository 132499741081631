import React from "react";
import "./App.css";
import db from "./utils/firebase";
import { collection, addDoc } from "firebase/firestore";
import axios from "axios";

const App = () => {
  const [data, setData] = React.useState({
    name: "",
    email: "",
    phone: "",
  });

  // const [shortUrl, setShortUrl] = React.useState("");
  const [error, setError] = React.useState("");

  const submitHandler = (event) => {
    event.preventDefault();

    const url = "/api/";
    const apiData = {
      account_id: "acc_F0b766GJGQCcVR",
      plan_id: "plan_F0bdfk0LF7IHcK",
      total_count: 24,
      quantity: 1,
      notes: {
        notes_key_1: `${data.name}`,
        notes_key_2: `${data.email}`,
        notes_key_3: `${data.phone}`,
        notes_key_4: "Website",
      },
    };

    addDoc(collection(db, "brigade-details"), data)
      .then((dbres) => {
        // Call razar pay API
        axios
          .post(url, apiData)
          .then((axios_response) => {
            // console.log("Response", axios_response);
            let link = axios_response.data.short_url;
            // console.log("Link:", link);
            if (link.startsWith("https:")) {
              window.location.replace(link);
            } else {
              setError(`Invalid Url : ${link} returned. Please try again...`);
            }
          })
          .catch((axios_error) => {
            console.log(axios_error.response.data);
            setError(axios_error.response.data);
          });
      })
      .catch((err) => console.log(err));
  };

  const changeHandle = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <div className="App">
      <h1 className="app_head">Prayaas Brigade Subscription Form</h1>
      <img
        className="app_image"
        src="https://www.prayaascorps.com/wp-content/uploads/2019/02/PC1.png"
        alt="Prayaas logo"
      />
      <p className="app_desc">
        We ensure that every single Penny what you donate will be solely used
        for Charity purpose.
      </p>
      <form onSubmit={submitHandler} className="app_form">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Enter Your name.."
          value={data.name}
          onChange={changeHandle}
          required
        />
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter you email.."
          value={data.email}
          onChange={changeHandle}
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          required
        />
        <label htmlFor="phone">Phone no.</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="Enter you phone number with country code..e.g. +919876543210"
          value={data.phone}
          onChange={changeHandle}
          pattern="^\+[1-9]{1}[0-9]{3,14}$"
          required
        />
        <button type="submit" className="submit">
          Submit
        </button>
      </form>
      <p style={{ color: "red" }}>{error}</p>
    </div>
  );
};

export default App;
